<template>
    <section style="padding:20px 15px" >
        <div class="content">
            <div class="drug-container">
                <div class="drg-list">
                    <h2 class="drg-header drug1">
                        <img src="//i2.hdfimg.com/ssi/pcdrugyp/prd/image/drug2.png" alt="疾病知识"><span style="color:#e6a23c">健康知识【收费】</span>
                    </h2>
                    <div class="drg-con drug-normal drug-nopadding" >
                           <ul v-show="artList.length">
                            <li class="m-u-li" v-for="a in artList"  :key="a.id" >
                                <router-link class="m-u-l-link" :to="a.to" style="text-decoration: none">
                                 <em class="m-u-l-label">10元</em>
                                 <div v-html="a.tmpl"></div>
                                 </router-link>
                            </li>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
// import hbanner  from'./banner';

import mock from '../../mock/drug.js'
import request from "../api/index";
export default {
       data(){
        return {
            sort:mock.sort,
            artLabelList: mock.artLabelList,
            artList:[]
        };
       },
       components: {},
       created() {
            const success = (res) => {
            this.artList = res.res;
            }
            request(
            `naskpackage/getartlist?cId=3000000`,
            success
            );
        }
}
</script>
<style>
a{text-decoration: underline;}
.m-u-l-link{
    position: relative;
}
.l-title{
    position: relative;
    padding-left:35px;
    z-index: 1;
}
    .m-u-l-label{
        font-style: normal;
        display:inline-block;
        padding:2px;
        position: absolute;
        top:15px;left:0;
        color: red;
        font-size: 12px;
        border: 1px solid #f80;
        border-radius: 5px;
        z-index: 12;
    }
    .m-u-li {

    border-bottom: 1px solid #ccc
}

.m-u-li:last-child {
    border-bottom: none
}

.m-u-li .m-u-l-link {
    display: block;
    color: #000;
    padding: 15px 15px 15px 0
}

.m-u-li .m-u-l-link .l-title {
    font-size: 17px;
    margin-bottom: 5px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.m-u-li .m-u-l-link .l-title .js-titletext {
    margin-right: 5px
}

.m-u-li .m-u-l-link .l-title .l-t-pay {
    position: relative;
    top: -1px;
    font-size: 12px;
    border: 1px solid #ff8c28;
    color: #ff8c28;
    font-weight: 400;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 0 4px;
    white-space: nowrap
}

.m-u-li .m-u-l-link .l-label {
    margin-bottom: 5px
}



.m-u-li .m-u-l-link .l-info {
    position: relative;
    clear: both;
    overflow: hidden
}



.m-u-li .m-u-l-link .l-info .l-i-doctor .l-i-d-name {
    color: #333;
    font-size: 14px
}

.m-u-li .m-u-l-link .l-info .l-i-doctor .l-i-d-hospital,.m-u-li .m-u-l-link .l-info .l-i-doctor .l-i-d-title {
    color: #646464;
    font-size: 14px
}





.m-u-li .m-u-l-link .l-info .l-i-publish {
    color: #646464;
    font-size: 14px
}

.m-u-li .m-u-l-link .l-info .l-i-ear {
    font-size: 13px;
    color: #646464
}


.m-u-li .m-u-l-link .l-info .l-i-ear .l-i-e-number {
    font-size: 13px
}

.m-u-li .m-u-l-link .l-good {
    clear: both;
    overflow: hidden;
    margin-top: 5px
}

.m-u-li .m-u-l-link .l-good .l-g-left {
    float: left;
    max-width: 80%
}

.m-u-li .m-u-l-link .l-good .l-g-left .l-g-latest {
    margin-right: 10px;
    color: #ff8c28;
    font-size: 14px
}

.m-u-li .m-u-l-link .l-good .l-g-left .img {
    display: inline-block;
    width: 15px;
    height: 15px;
    -webkit-background-size: 15px 15px;
    -moz-background-size: 15px 15px;
    -o-background-size: 15px 15px;
    background-size: 15px 15px;
    vertical-align: middle;
    line-height: 15px;
    position: relative;
    top: -2px
}

.m-u-li .m-u-l-link .l-good .l-g-left .al_video {
    background-image: url(//fe1.hdfimg.com/narticle/waparticle/images/al_video.png)
}

.m-u-li .m-u-l-link .l-good .l-g-left .al_aodio {
    background-image: url(//fe1.hdfimg.com/narticle/waparticle/images/al_aodio.png)
}

.m-u-li .m-u-l-link .l-good .l-g-left .al_article {
    background-image: url(//fe1.hdfimg.com/narticle/waparticle/images/al_article.png)
}

.m-u-li .m-u-l-link .l-good .l-g-left .al_question {
    background-image: url(//fe1.hdfimg.com/narticle/waparticle/images/al_aodio.png)
}

.m-u-li .m-u-l-link .l-good .l-g-left .l-g-good {
    font-size: 14px
}

.m-u-li .m-u-l-link .l-good .l-g-left .l-g-percent {
    font-size: 17px;
    color: #ff8c28
}

.m-u-li .m-u-l-link .l-good .l-g-left .l-g-nothing {
    font-size: 14px;
    color: #969696
}

.m-u-li .m-u-l-link .l-good .l-g-left .l-g-comment {
    font-size: 14px;
    color: #646464
}

.m-u-li .m-u-l-link .l-good .paytag {
    float: right;
    display: inline-block;
    margin-top: 4px;
    border: 1px solid #757575;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 12px;
    padding: 0 2px;
    height: 14px;
    line-height: 15px;
    color: #646464
}

.m-u-li .m-u-l-link .l-good .l-g-right {
    display: inline-block;
    font-size: 14px;
    color: #ff8c28;
    line-height: 24px
}

.m-u-li .m-u-l-link .l-comment {
    clear: both;
    overflow: hidden;
    margin-top: 5px;
    position: relative
}

.m-u-li .m-u-l-link .l-comment .l-c-wrap {
    height: 22px;
    overflow: hidden;
    padding-right: 38px
}

.m-u-li .m-u-l-link .l-comment .l-c-wrap .l-c-w-label {
    height: 20px;
    line-height: 20px;
    font-size: 0;
    display: inline-block;
    background-color: #fff;
    border: 1px solid #c8c8c8;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0 4px
}

.m-u-li .m-u-l-link .l-comment .l-c-wrap .l-c-w-label:last-child {
    margin-right: 0
}

.m-u-li .m-u-l-link .l-comment .l-c-wrap .l-c-w-label .l-text {
    font-size: 14px;
    color: #323232;
    margin-right: 5px
}

.m-u-li .m-u-l-link .l-comment .l-c-wrap .l-c-w-label .l-num {
    font-size: 14px;
    color: #46a0f0
}

.m-u-li .m-u-l-link .l-comment .l-c-more {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    font-size: 14px;
    color: #46a0f0;
    line-height: 22px
}
</style>
<style scoped>
.drg-list{padding-bottom:36px;}
.drg-list .drg-header{height:51px;padding-bottom: 10px;}
.drg-list .drg-header img{float:left;width:51px;height:51px;margin-right:16px}
.drg-list .drg-header span{display:inline-block;font-size:20px;color:#ff4d52;line-height:51px}
/* .drug-normal{padding-left:20px} */
.drug1{border-bottom:1px solid #ff8f8f}
.drug2{border-bottom:1px solid #ffc673}
.drg-list .drug2 span{color:#e88d08}
.drug3{border-bottom:1px solid #5bc5d7}
.drg-list .drug3 span{color:#5bc5d7}
.drg-wrap{border-bottom:1px solid #dcdcdc}
.drg-wrap .drg-tit{float:left;line-height:1;padding:20px 0;font-size:18px;color:#000;width:102px}
.drg-con a,.drg-con span{display:inline-block;font-size:16px;color:#3f89ec;line-height:1;padding:20px 38px 0 0}
.drg-wrap .drg-con{line-height:1;margin-left:102px;padding-bottom:20px}
.drug-normal a{padding-bottom:0}
.drug-container .drg-list:last-child{padding-bottom:0}
.drug-con a{text-decoration:none;color:#2670d5;outline:0}


</style>
